<template>
  <div class="h-full flex flex-col pb-0">
    <loader :loading="this.loading" :backdrop="true" />
    <div class="form-row">
      <div class="form-col">
        <label for="categories">main topic</label>
        <Dropdown
          trackBy="intent_category_name"
          label="intent_category_name"
          :options="categories"
          :value="category"
          @input="setCategoryValue"
          objectMode
        />
        <label for="language" class="mt-4">language</label>
        <Dropdown
          trackBy="label"
          label="label"
          :options="[{label: 'english', value: 'en'}, {label: 'spanish', value: 'es'}]"
          :value="this.language"
          @input="setLanguage"
          :disabled="!this.subIntent"
          objectMode
        />
      </div>
      <div class="form-col">
        <label for="intents" :class="{ isDisabled: !this.category }"
          >conversation detail</label
        >
        <Dropdown
          trackBy="intent_name"
          label="suggestion_text"
          :options="intents"
          :value="intent"
          @input="setIntentValue"
          :disabled="!this.category"
          objectMode
        />
      </div>
      <div class="form-col">
        <label for="sub_intent" :class="{ isDisabled: !this.intent }"
          >next level detail</label
        >
        <Dropdown
          trackBy="intent_name"
          label="suggestion_text"
          :options="subIntents"
          :value="subIntent"
          @input="setSubIntentValue"
          :disabled="!this.intent"
          objectMode
        />
      </div>
    </div>
    <div
      class="form overflow-auto mt-3"
      v-if="category && intent && subIntent && this.language && this.intent_name"
    >
      <div class="form-row">
        <div class="form-col">
          <label for="intent_name">intent name</label>
          <div class="element font">{{this.intent_name}}</div>
        </div>
        <div class="form-col">
          <label>conversation detail</label>
          <div class="element font">{{this.parent_suggestion_text}}</div>
        </div>
        <div class="form-col">
          <label>next level detail</label>
          <div class="element font">{{this.suggestion_text}}</div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-col">
          <label>input context</label>
          <div class="container-element">
            <div class="element bordered font" v-for="i in this.input_context" :key="i">{{i}}</div>
          </div>
        </div>
        <div class="form-col flex">
          <div class="w-3/4 mr-1">
            <label>output context</label>
            <div class="container-element">
              <div class="element bordered font" v-for="i in this.ouput_context" :key="i.name">{{i.name}}</div>
            </div>
          </div>
          <div class="w-1/5">
            <label>span</label>
            <div class="container-element">
              <div class="element bordered font" v-for="i in this.ouput_context" :key="i.lifespan">{{i.lifespan}}</div>
            </div>
          </div>
        </div>
        <div class="form-col">
          <label>intent description</label>
          <div class="element font">{{this.intent_description}}</div>
        </div>
      </div>
      <div class="form-row mt-8">
        <div class="form-col">
          <label>dialogflow responses</label>
          <div class="h-12 bordered font overflow-y-auto" v-for="i in this.text_response" :key="i">{{i}}</div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-col">
          <label>training phrases</label>
          <div class="container-element">
            <div class="element bordered font" v-for="i in this.training_phrases" :key="i">{{i}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AuthMixin from '@/components/auth/AuthMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import ModalNavigation from '@/mixins/ModalNavigation'
import Loader from '@/components/ui/Loader'
import Dropdown from '@/components/ui/Dropdown'
import ConversationMixin from '@/components/digital_human/ConversationMixin'

export default {
  mixins: [AuthMixin, NotifyMixin, ModalNavigation, ConversationMixin],
  components: {
    Loader,
    Dropdown,
  },
  computed: {
    ...mapGetters({
      backModal: 'modals/getLastModal',
    }),
  },
  data() {
    return {
      language: null,
      intent_name: '',
      parent_suggestion_text: '',
      suggestion_text: '',
      input_context: [],
      ouput_context: [],
      intent_description: '',
      text_response: [],
      training_phrases: [],
    }
  },
  methods: {
    fetchCategories() {
      this.setLoading(true)
      this.$dhDataProvider
        .getList('categories')
        .then((res) => this.loadCategories(res))
        .catch((error) => this.notifyError(error.message))
        .finally(() => this.setLoading(false))
    },
    fetchIntents(categoryName) {
      this.setLoading(true)
      this.$dhDataProvider
        .getList('intents', { category: categoryName})
        .then((res) => this.loadIntents(res))
        .catch((error) => this.notifyError(error.message))
        .finally(() => this.setLoading(false))
    },
    fetchSubIntents(intentName) {
      this.setLoading(true)
      this.$dhDataProvider
        .getList('subIntentsDetail', {
          intent: intentName,
        })
        .then((res) => this.loadSubIntents(res))
        .catch((error) => this.notifyError(error.message))
        .finally(() => this.setLoading(false))
    },
    clearIntent() {
      this.intent_name = '';
      this.parent_suggestion_text = '';
      this.suggestion_text = '';
      this.input_context = [];
      this.ouput_context = [];
      this.intent_description = '';
      this.text_response = [];
      this.training_phrases = [];
    },
    fetchIntent(intentName) {
      this.setLoading(true)
      this.clearIntent()
      this.$dhDataProvider
        .get('getIntent', {
          language: encodeURIComponent(this.language.value),
          intent: encodeURIComponent(intentName),
        })
        .then((res) => {
          this.intent_name = res.intent_name;
          this.parent_suggestion_text = res.parent_suggestion_text;
          this.suggestion_text = res.suggestion_text;
          this.input_context = res.input_context;
          this.ouput_context = res.ouput_context;
          this.intent_description = res.intent_description;
          this.text_response = res.text_response;
          this.training_phrases = res.training_phrases;
        })
        .catch((error) => this.notifyError(error.message))
        .finally(() => this.setLoading(false))
    },
    setCategoryValue(value) {
      this.cleanState('category')
      if (!value) return
      this.setCategory(value)
      this.fetchIntents(value?.description)
    },
    setIntentValue(value) {
      this.cleanState('intent')
      this.cleanState('subIntent')
      if (!value) return
      this.setIntent(value)
      this.fetchSubIntents(value?.intent_name)
    },
    setSubIntentValue(value) {
      this.cleanState('subIntent')
      if (!value) return
      this.setSubIntent(value)
      if(this.language) {
        const subIntentValue =
          this.subIntent.intent_name === 'general' ? this.intent : value
        this.fetchIntent(subIntentValue.intent_name)
      }
    },
    setLanguage(value) {
      this.language = null;
      if(!value) return
      this.language = value;
      const subIntentValue =
        this.subIntent.intent_name === 'general' ? this.intent : this.subIntent
      this.fetchIntent(subIntentValue.intent_name)
    }
  },
  created() {
    this.fetchCategories()
    if (this.community) {
      if (this.category && this.intent && this.subIntent && this.language) {
        const value =
          this.subIntent?.intent_name === 'general'
            ? this.intent
            : this.subIntent
        this.fetchIntent(value.intent_name)
      }
    }
  },
  unmounted() {
    if (!this.backModal) {
      this.cleanState('category')
    }
  },
}
</script>

<style scoped>
.isDisabled {
  @apply text-gray-400 !important;
}
.element {
  @apply w-full h-10 overflow-auto;
}
.bordered {
  @apply border border-gray-300 rounded-xs outline-none py-2 px-3;
}
.container-element {
  max-height: 10rem;
  overflow: auto;
}
.font {
  @apply font-400 text-base;
}
</style>
